<template>
  <BodySimple>
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/business-startup.png" class="h-20 inline-block" />
        تبلیخات روزانه
        <bdi>{{ $route.params.day }}</bdi>
      </h2>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-event-96.png" class="inline-block h-20" />
          تبلیغات سراسری گرو ها
        </div>
        <div class="grid sm:grid-cols-3 2xl:grid-cols-4 gap-3">
          <div
            v-for="ad in groups"
            :key="ad.id"
            class="border bg-blueGray-50 border-t-8 p-3 rounded"
            :class="{
              'border-green-400': ad.status == 'end',
              'border-cyan-400': ad.status == 'running',
              'border-amber-400': ad.status == 'wait',
              'border-red-400': ad.status == 'incomplete',
            }"
          >
            <div>
              <router-link
                class="text-sky-500"
                :to="{
                  name: 'Panel',
                  params: {
                    id: ad.user.panel.id,
                  },
                }"
              >
                {{ ad.user.first_name }} {{ ad.user.last_name }}
              </router-link>
            </div>
            <div dir="ltr">
              id: {{ ad.user.username ? "@" + ad.user.username : "ندارد" }}
            </div>
            <div>
              بازدید: {{ ad.views.length ? $number.format(ad.views[0]?.view) : "ندارد" }}
            </div>
            <div>کلید: {{ ad.keys.length ? ad.keys.length : "ندارد" }}</div>
            <div v-if="ad.keys.length">
              <div
                v-for="key in ad.keys"
                :key="key.id"
                class="p-2 my-2 border border-blueGray-200"
              >
                <div>{{ key.text }}</div>
                <div dir="ltr">{{ key.link }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
export default {
  components: { BodySimple },
  data() {
    return {
      groups: [],
    };
  },
  mounted() {
    this.getDaily();
    // this.getLasts();
  },
  methods: {
    getDaily: async function () {
      try {
        const response = await this.$axios.get("api/admin/ads/daily", {
          params: { day: this.$route.params.day },
        });
        // console.log(response);
        this.groups = response.data.groups;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
